<template>
    <div class="mt-16">
        <Services/>
        <Portfolio/>
    </div>
</template>

<script>
import Services from '@/components/Services.vue';
import Portfolio from '@/components/Portfolio.vue';
export default {
    name: 'ServicesView',
    components: {
        Services,
        Portfolio,
    }
}
</script>
