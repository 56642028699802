<template>
    <div>
        <section id="services" class="services section-bg bg-gray-100 rounded-2xl px-5 dark:bg-gray-800">
        <div class="container mx-auto" data-aos="fade-up">

            <div class="text-center section-title">
                <h2 class="
                    text-5xl font-semibold 
                    text-gray-900 
                    
                    dark:text-white
                ">{{ $t('our services') }}</h2>
                <p class="text-2xl mt-8 p-2 rounded-2xl  text-secondary-600 dark:text-gray-100">{{ $t('services title') }}</p>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mt-8">
                <div v-for="(service, index) in services" :key="index">
                    <div class="relative">
                    <div class="flex items-stretch bg-cover bg-center h-64 rounded-xl" 
                        :style="{ 'background-image': 'url('+service.image+')' }" 
                        data-aos="zoom-in" data-aos-delay="100">
                        <div class="text-black text-5xl absolute bottom-0 start-0 w-full
                                    bg-gradient-to-b from-white/5 to-white h-17 rounded-lg">
                                    <h1 class="m-2 ltr:block hidden">
                                        {{ service.name_en }}
                                    </h1>
                                    <h1 class="m-2 ltr:hidden block">
                                        {{ service.name_ar }}
                                    </h1>
                        </div>
                        <div class="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-lg 
                                    icon-box block h-full absolute inset-0 items-center 
                                    justify-center  opacity-0
                                    hover:opacity-100 transition-opacity duration-300"
                                    >
                        <div class="text-center">
                            <box-icon color="#f38f20" :name="service.icon" size="lg" ></box-icon>
                            <h4 class="text-3xl font-bold mt-4 "><a href="#" class="text-gray-900 dark:text-gray-200">{{ service.title }}</a></h4>
                            <p class="text-xl text-gray-600 mt-2 dark:text-gray-300">{{ service.description }}</p>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
    </div>
</template>
<script>

import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {

    setup(){
        const services = ref([])
        onMounted(async () => {
            try {
                const response = await axios.get('/services/')
                services.value = response.data.map(service => {
                const parts = service.name.split(' - ');
                    return {
                        ...service,
                        name_ar: parts[0] || '',
                        name_en: parts[1] || '',
                    };
                });
            } catch (error) {
                console.error("Error fetching services:", error);
            }
        });

        return {
            services,
        }
    }
}

</script>