<template>
    <div>
        <section id="portfolio" class="portfolio">
            <div class="container mx-auto px-4" data-aos="fade-up">
                <div class="text-center section-title">
                    <h2 class="text-5xl font-semibold text-primary-600">{{ $t("our products") }}</h2>
                    <p class="text-2xl mt-8 p-2 rounded-2xl text-gray-900 dark:text-gray-100">
                        {{ $t('check out our newest products') }}
                    </p>
                </div>
                <div class="flex justify-center mt-8" dir="ltr" data-aos="fade-up" data-aos-delay="100">
                    <div class="col-lg-12">
                        <ul id="portfolio-flters" class="flex flex-wrap flex-col sm:flex  text-2xl">
                            <li v-for="(category, index) in categories" :key="index"
                                @click="setActiveFilter(category.name)"
                                :class="{ 'filter-active': activeFilter === category.name }" 
                                class="text-secondary-600 dark:text-stone-100"
                            >
                            <h1 class="m-2 ltr:block hidden">
                                {{ category.name_en }}
                            </h1>
                            <h1 class="m-2 ltr:hidden block">
                                {{ category.name_ar }}
                            </h1>
                            </li>
                            <!-- <li @click="setActiveFilter('*')" :class="{ 'filter-active': activeFilter === '*' }" 
                                class="text-secondary-600 dark:text-stone-100"
                            >الكل</li>                             -->
                        </ul>
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8" data-aos="fade-up"
                    data-aos-delay="200">
                    <div v-for="(item, index) in filteredItems" :key="index">
                        <div class="portfolio-item" data-aos="zoom-in" data-aos-delay="500">
                            <div class="portfolio-wrap h-72">
                                <img v-if="item.images && item.images.length > 0 && item.images[0].image" 
                                    :src="item.images[0].image" alt="logo" class="w-full h-full object-cover">
                                <img v-else src="../assets/logo/logo.png" class="w-full h-full object-cover " :alt="item.name">
                                <div class="portfolio-links">
                                    <a v-if="item.images && item.images.length > 0 && item.images[0].image" 
                                            :ref="item.images[0].image" data-gallery="portfolioGallery"
                                        class="portfolio-lightbox"><box-icon name="plus" color="#f38f20"
                                            size="20px"></box-icon></a>
                                    <a v-else ref="../assets/logo/logo.png" data-gallery="portfolioGallery"
                                        class="portfolio-lightbox"><box-icon name="plus" color="#f38f20"
                                            size="20px"></box-icon></a>
                                    <router-link :to="{ name: 'ServiceDetails', params:{ id:item.id } }" class="ml-2">
                                        <box-icon name="link" color="#f38f20" size="20px"></box-icon>
                                    </router-link>
                                </div>
                                <div class="portfolio-info p-2">
                                    <h4 class="mt-2 text-2xl">
                                        {{ item.name }}
                                    </h4>
                                    <p class="text-xl"> {{ item.category.name }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>


import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

export default {
    setup() {
        const activeFilter = ref('*')
        const items = ref([])
        const categories = ref([])
        onMounted(async () => {
            try {
                const response = await axios.get('/products/')
                const categories_response = await axios.get('/product-categories/')
                categories.value = categories_response.data.map(category => {
                const parts = category.name.split(' - ');
                    return {
                        ...category,
                        name_ar: parts[0] || '',
                        name_en: parts[1] || '',
                    };
                });
                items.value = response.data
                filteredItems.value = response.data
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        });
        const filteredItems = computed(() => {
            if (activeFilter.value === '*') {
                return items.value;
            } else {
                return items.value.filter(item => item.category.name === activeFilter.value);
            }
        });
        const setActiveFilter = (filter) => {
            activeFilter.value = filter;
        };
        return {
            activeFilter,
            filteredItems,
            setActiveFilter,
            categories,
        };
    },
};

</script>