<template>
    <div>
    <div class="fixed end-6 bottom-6 group">
    <div id="speed-dial-menu-default" class="flex flex-col items-center mb-4 space-y-2">
    </div>
    <a href="https://wa.me/+966539797972" type="button"
    style="color: white;"
    class="
    flex items-center justify-center
    bg-primary-600 rounded-full w-14 h-14 
    hover:bg-primary-800 dark:bg-primary-600 
    dark:hover:bg-primary-600 focus:ring-4
    focus:ring-bg-primary-100
    text-white
    focus:outline-none dark:focus:ring-primary-600">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 transition-transform" fill="currentColor" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
        </svg>
    </a>
</div>
<footer class="bg-white rounded-lg dark:bg-gray-900 m-4">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
            <a href="https://flowbite.com/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                <img src="../assets/logo/logo.png" class="h-8" alt="Smart Time Logo" />
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">{{ $t('smartime') }}</span>
            </a>
            <ul class="flex flex-wrap items-center mb-6 text-xl font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                <li>
                    <router-link to="/" class="hover:underline me-4 md:me-6 text-secondary-600 dark:text-slate-50">{{ $t('home') }}</router-link>
                </li>
                <li>
                    <router-link to="/about" class="hover:underline me-4 md:me-6 text-secondary-600 dark:text-slate-50">{{ $t('about') }}</router-link>
                </li>
                <li>
                    <router-link to="/services" class="hover:underline me-4 md:me-6 text-secondary-600 dark:text-slate-50">{{ $t('services') }}</router-link>
                </li>
                <li>
                    <router-link to="/contact" class="hover:underline text-secondary-600 dark:text-slate-50 ">{{ $t('contact us') }}</router-link>
                </li>
            </ul>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span class="block text-lg text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://smartime.ai/" class="hover:underline">{{ $t('smartime') }}</a>{{ $t("copy rights") }} </span>
        <span class="block text-md text-gray-500 sm:text-center dark:text-gray-400"><span>{{ $t('Developed by') }}</span>  <a href="https://eshaibani.com/">{{ $t("Ebrahim Hasan") }} </a></span>
    </div>
</footer>


    </div>
</template>