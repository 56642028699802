<template>
    <div>
        
        <section id="hero" class="flex relative items-center bg-primary-100 dark:bg-gray-800 rounded-2xl p-5 mt-20">
            <div class="container mx-auto">
                <div class="absolute inset-0 dark:bg-black/50 bg-white/50"></div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                    <div class="md:order-2 flex flex-col justify-center">
                        <h1 class="text-4xl md:text-5xl lg:text-6xl font-bold dark:text-white text-gray-900" data-aos="fade-left"><span class="text-primary-600 dark:text-primary-300"> {{ $t('hero title 1') }} </span> {{ $t('hero title 2') }}</h1>
                        <h2 class="text-2xl md:text-3xl lg:text-4xl text-gray-700 dark:text-white" data-aos="fade-left">{{ $t('hero description') }}</h2>
                        <div>
                            <router-link to="/about" data-aos="fade-up"
                                class="btn-get-started inline-block text-xl 
                                        md:text-2xl lg:text-3xl 
                                        px-6 py-3 rounded-md bg-primary-500 
                                        text-white hover:bg-primary-600">
                                {{ $t("see more") }}
                            </router-link>
                        </div>
                    </div>
                    <div class="md:order-1">
                        <img src="../assets/img/hero-img2.svg"  
                            class="w-full md:mx-auto animate-up-down" 
                            alt="hero image"
                            data-aos="zoom-in">
                    </div>
                </div>
            </div>
        </section>
</div></template>