<template>
  <div>
    <!-- <Animation/> -->
    <Hero/>
    <About/>
    <Services/>
    <!-- <Portfolio/> -->
    <Profile/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
// import Animation from '@/components/Animation.vue';
import About from '@/components/About.vue';
import Services from '@/components/Services.vue';
import Portfolio from '@/components/Portfolio.vue';
import Profile from '@/components/Profile.vue';
export default {
  name: 'HomeView',
  components: {
    Hero,
    About,
    Services,
    Portfolio,
    Profile,
  }
}
</script>
