<template>
  <div :dir="$i18n.locale === 'AR' ? 'rtl' : 'ltr'" class="dark:bg-gray-900">
  <Header/>
  <!-- <BackgroundEffect /> -->
  <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
  <Footer/>
  </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue'
// import BackgroundEffect from './components/BackgroundEffect.vue';
export default {
  components:{
    Header,
    Footer,
    // BackgroundEffect,
  },
  setup(){

    return {

    }
  }
}

</script>
<style>

.route-enter-from{
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active{
  transition: all 0.5s ease-out;
}
.route-leave-to{
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active{
  transform: translateX(-100px);
}

</style>