<template>
    <div>
        <div>
        <main>
            <section class="px-4 py-12 md:py-16 sm:px-6 lg:px-8 ">
                <div class="max-w-screen-xl mx-auto">
                <div class="grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-16 p-10 bg-white dark:bg-gray-800 rounded-2xl">
                    <div
                    class="flex flex-col items-start justify-between sm:items-center lg:items-start"
                    >
                    <div>
                        <div class="w-full text-left sm:text-center lg:text-left">
                            <SectionTitle msg="Contact" />
                        </div>
                        <div
                        class="w-full mt-4 text-left md:mt-5 sm:text-center lg:text-left"
                        >
                        <h1 data-aos="fade-up" data-aos-duration="500"
                            class="text-4xl font-extrabold text-gray-800 dark:text-slate-100 sm:text-5xl md:text-6xl"
                        >
                            {{ $t('Get in touch') }}
                        </h1>
                        </div>
                    </div>

                    <div
                        class="grid gap-6 mt-8 sm:mt-10 lg:mt-0 sm:grid-cols-2 sm:grid-rows-2 sm:gap-12"
                    >
                        <div data-aos="zoom-out" data-aos-duration="500">
                        <h5 class="text-3xl font-bold text-gray-800 dark:text-slate-100">{{ $t('riyadh, saudi arabia') }}</h5>
                        <p class="mt-1 text-xl text-gray-500 dark:text-slate-200">
                            {{ $t('riyadh, saudi arabia') }} <br>
                            {{ $t('riyadh, saudi arabia') }}
                        </p>
                        </div>
                        <div data-aos="zoom-out" data-aos-duration="750">
                        <h5 class="text-3xl font-bold text-gray-800 dark:text-slate-100">{{ $t('call us') }}</h5>
                        <p class="mt-1 rtl:text-end text-xl text-gray-500 dark:text-slate-200" dir="ltr">
                            +966 053 97 97 97 1
                            <br/>+966 053 97 97 97 2
                        </p>
                        </div>

                        <!-- Location 3 -->
                        <div data-aos="zoom-out" data-aos-duration="1000">
                        <h5 class="text-3xl font-bold text-gray-800 dark:text-slate-100">{{ $t('work hours') }}</h5>
                        <p class="mt-1 text-xl  text-gray-500 dark:text-slate-200">
                            {{$t('Sunday - Thursday')}}
                            <br />{{$t('9:00AM - 05:00PM')}}
                        </p>
                        </div>

                        <!-- Location 4 -->
                        <div data-aos="zoom-out" data-aos-duration="1250">
                        <h5 class="text-3xl font-bold text-gray-800 dark:text-slate-100">{{ $t('email us') }}
                        </h5>
                        <p class="mt-1 text-xl text-gray-500 dark:text-slate-200">
                                info@smartime.ai
                            <br />
                        </p>
                        </div>
                    </div>
                    <div class="w-full mt-8 sm:mt-10 lg:mt-0">
                        <h6 class="text-3xl font-semibold text-left text-primary-600 sm:text-center lg:text-left" >
                            {{ $t('follow us') }}
                        </h6>
                        <div data-aos="zoom-in" dir="ltr"
                        class="flex justify-start mt-3 space-x-4 sm:justify-center lg:justify-start" >
                        <a class="flex items-center hover:text-white justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full bg-gradient-to-r dark:from-primary-600 dark:to-dark-700 from-primary-600 to-primary-600 text-white"
                            href="#" >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="4" width="16" height="16" rx="4" />
                            <circle cx="12" cy="12" r="3" />
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full bg-gradient-to-r dark:from-primary-600 dark:to-dark-700 from-primary-600 to-primary-600 text-white"
                            href="#">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                            />
                            </svg>
                        </a>
                        <a
                            class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full bg-gradient-to-r dark:from-primary-600 dark:to-dark-700 from-primary-600 to-primary-600 text-white"
                            href="#"
                        >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"
                            />
                            </svg>
                        </a>
                        </div>
                    </div>
                    </div>
                    <div class="w-full mt-12 lg:mt-0">
                        <iframe
                        class="w-full mt-12 rounded-2xl object-cover h-96"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1855562.0014300202!2d45.50324552512831!3d24.7219177798193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2sRiyadh%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1708693545263!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                </div>
            </section>
            </main>
    </div>
    </div>
</template>

<script>

export default {
    name: 'ContactView',
    components: {

    }
}
</script>
