<template>
    <div>
        <section id="about" class="about">
            <div class="container mx-auto">
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div class="flex items-center justify-center about-img">
                        <img src="../assets/img/about-imgs.svg" class="img-fluid" alt="" data-aos="zoom-in">
                    </div>
                    <div class="lg:pt-5">
                        <h3 class="text-3xl md:text-4xl lg:text-5xl font-bold dark:text-white text-gray-900" data-aos="fade-up">
                            {{ $t('who are we?') }}
                        </h3>
                        <p class="mt-4 dark:text-gray-100 text-gray-900 text-2xl md:text-3xl lg:text-3xl" data-aos="fade-up" data-aos-delay="100">
                            {{ $t('hero description') }}
                        </p>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                            <div class="flex items-center" data-aos="fade-up" data-aos-delay="100">
                                <div class="ml-2 ">
                                    <box-icon color="#f38f20" name="receipt" size="lg" ></box-icon>
                                    <h4 class="font-bold dark:text-white text-gray-900 text-3xl md:text-4xl lg:text-4xl">
                                        {{ $t('our values') }}
                                    </h4>
                                    <p class="mt-2 dark:text-gray-200 text-gray-900 text-2xl md:text-3xl lg:text-3xl">
                                        {{ $t('our values desc') }}
                                    </p>
                                </div>
                            </div>
                            <div class="flex items-center" data-aos="fade-up" data-aos-delay="200">
                                
                                <div class="ml-2">
                                    <box-icon color="#f38f20" name="cube-alt" size="lg" ></box-icon>
                                    <h4 class="font-bold dark:text-white text-gray-900 text-3xl md:text-4xl lg:text-4xl">
                                        {{ $t('our vision') }}
                                    </h4>
                                    <p class="mt-2 dark:text-gray-200 text-gray-900 text-2xl md:text-3xl lg:text-3xl">
                                        {{ $t('our vision desc') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</div>
</template>