<template>
    <div>
        <div
            class="h-96 text-start lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
            <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                <div class="relative">
                    <div class="absolute">
                        <div>
                            <h1 class="my-2 text-gray-800 dark:text-gray-200 font-bold text-2xl">
                                404
                            </h1>
                            <p class="my-2 text-gray-800 dark:text-gray-200">
                                {{ $t("404 message") }}
                            </p>
                            <router-link to="/" 
                                class="text-primary-600 font-extrabold">
                                {{ $t("return home") }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
</template>

<script>
export default {
    setup(){

        return {

        }
    }
}

</script>