<template>
    <div class="px-4 py-12 md:py-16 sm:px-6 lg:px-8">
    <section v-if="product" class="portfolio-details" id="portfolio-details">
            <nav class="flex w-full p-2 mb-5 " aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li class="inline-flex items-center">
                <router-link to="/" class="inline-flex items-center text-lg font-medium text-gray-700 hover:text-primary-600 dark:text-slate-200 dark:hover:text-white">
                    <svg class="w-3 h-3 me-2.5"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                    </svg>
                    {{ $t('home') }}
                </router-link>
                </li>
                <li>
                <div class="flex items-center">
                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 dark:text-slate-100 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                    </svg>
                    <router-link to="/services" class="ms-1 text-lg font-medium text-gray-700  hover:text-primary-600 md:ms-2 dark:text-slate-200 dark:hover:text-white">{{ $t('products') }}</router-link>
                </div>
                </li>
                <li aria-current="page">
                <div class="flex items-center">
                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1 dark:text-slate-100" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                    </svg>
                    <span class="ms-1 text-lg font-medium text-gray-500 md:ms-2 dark:text-slate-200">{{ product.name }}</span>
                </div>
                </li>
            </ol>
            </nav>

            <div class="container mx-auto">
                <div class="grid grid-cols-1 md:grid-cols-2 sm:grid-col-1 gap-4">
                <div class="col-span-1 order-2 md:col-span-1">
                    <h1 class="text-4xl text-secondary-800 dark:text-slate-100 my-5" > {{ product.category.name }}  {{ product.name }} </h1>
                    <div class="swiper portfolio-details-slider" ref="slider">
                    <div class="swiper-wrapper flex items-center">  
                        <swiper
                            :pagination="{ clickable: true }"
                            :grabCursor="grabCursor"
                            :effect="effect"
                            :loop="loop"
                            :centeredSlides=true
                            :autoplay="autoplay"
                            class="parallax-slider"
                            parallax
                            grabCursor
                            Autoplay 
                            EffectFade 
                        >
                        <swiper-slide class="swiper-slide" v-for="image in product.images" :key="image.id">
                            <div class="swiper-slide">
                                <img :src="image.image" :alt="image.id" class="rounded-xl">
                            </div>
                        </swiper-slide>
                    </swiper>
                    </div>
                    </div>

                </div>

                <div class="col-span-1 order-1 md:col-span-1 rounded-2xl shadow p-5">
                    <div class="portfolio-info">
                    <h3 class="text-4xl font-bold mb-2 text-secondary-600 dark:text-slate-100">{{ product.name }}</h3>
                    <ul>
                        <li class="text-2xl text-secondary-600 dark:text-slate-100"><span class="text-black dark:text-slate-100">الصنف</span>: {{ product.category.name }}</li>
                        <li v-if="product.price" class="text-2xl text-secondary-600 dark:text-slate-100"><span class="text-black dark:text-slate-100">السعر</span>: {{ product.price }}</li>
                    </ul>
                    </div>
                        <hr>
                    <div class="portfolio-description mt-4">
                    <h2 class="text-3xl font-bold mb-2 text-black dark:text-slate-100">
                        مواصفات المنتج
                    </h2>
                    <p class="text-secondary-600 dark:text-slate-200 text-xl">
                        {{ product.description }}
                    </p>
                    </div>
                </div>

                </div>

            </div>

    </section>
<div v-else>
    <div class="text-center">
        <div role="status">
            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
    </div>
</template>

<script>
    import { ref, computed, onMounted , onBeforeUnmount  } from 'vue';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
    // import 'swiper/swiper.scss';
    // import 'swiper/components/navigation/navigation.scss'; // Optional for navigation arrows
    // import 'swiper/components/pagination/pagination.scss'; // Optional for pagination dots

    // import SwiperCore from 'swiper';  
    // import { Swiper, SwiperSlide } from 'swiper/vue';  
    import 'swiper/css';
    import 'swiper/css/navigation';
    import axios from 'axios';
    import {useRoute} from "vue-router";
    export default {
        components: {
            Swiper,
            SwiperSlide,
        },
        setup(){
            SwiperCore.use([Navigation, Pagination, Autoplay]);

            const slider = ref(null);
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = () => {
                console.log('slide change');
            };
            const product = ref(null)
            const route = useRoute();
            const id = route.params.id;
            const swiperOptions =  ref({
                slidesPerView: 1, // Adjust the number of visible slides
                spaceBetween: 20, // Adjust the gap between slides
                navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                }, // Enable navigation arrows
                pagination: {
                el: '.swiper-pagination',
                clickable: true,
                }, // Enable pagination dots (optional)
                autoplay: { // Enable autoplay (optional)
                delay: 3000, // Adjust autoplay delay in milliseconds
                disableOnInteraction: false, // Disable autoplay on user interaction (optional)
                },
            })
    

            onMounted(async () => {
                    try {
                        const response = await axios.get(`/products/${id}/`)
                        product.value = response.data
                    } catch (error) {
                        console.error("Error fetching products:", error);
                    }
            })
        return {
            product,
            onSwiper,
            onSlideChange,
            slider,
            swiperOptions,
            parallaxSwiperWidth: 0,
            }
        }
    };
</script>